import { getRoute, PATH } from '@/utils/routes';
import React, { useEffect, useState } from 'react';
import { MoreCarat, LessCarat } from './svgIcons';
import router from 'next/router';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const RenderCategory = (props) => {
  const {
    categories,
    onClose,
    collectionId,
    customLinkStyling,
    parentIndex = -1,
  } = props;
  const { onLinkHoverEnter, onLinkHoverLeave, getNavElementStyle } =
    customLinkStyling || {};
  const [currCategories, setCurrCategories] = useState([]);
  const [showMoreLess, setShowMoreLess] = useState({
    more: false,
    less: false,
  });
  const moreLimit = props.showMoreCount || 36;

  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  useEffect(() => {
    if (categories.length < moreLimit) {
      setCurrCategories(categories);
    } else if (categories.length >= moreLimit) {
      setShowMoreLess({ more: true, less: false });
      setCurrCategories(categories.slice(0, moreLimit));
    }
  }, [categories]);

  useEffect(() => {
    if (showMoreLess.more) {
      setCurrCategories(categories.slice(0, moreLimit));
    } else {
      setCurrCategories(categories);
    }
  }, [showMoreLess]);

  const totalCategories = currCategories?.length;
  const maxCols = Math.min(6, Math.ceil(totalCategories / 6));
  const maxRows = Math.max(6, Math.ceil(totalCategories / maxCols));

  const handleCategoryClick = (category) => {
    if (category.isMoreLess) return;
    router.push(
      getRoute(
        `${PATH.collectionCategories(collectionId)}?cid=${category.id}`,
        storeData?.store_info?.domain
      ),
      undefined,
      { shallow: true, scroll: true }
    );
    onClose && onClose();
  };

  function splitObjectsIntoGroups(objects, batchSize) {
    const result = [];
    for (let i = 0; i < objects.length; i += batchSize) {
      const batch = objects.slice(i, i + batchSize);
      result.push(batch);
    }
    return result;
  }

  const displayMoreLess = (type, styling = {}) => {
    return (
      <div
        className="flex tw-relative"
        style={{ gap: '2px', alignItems: 'center', cursor: 'pointer' }}
        onClick={handleMoreLessClick}
      >
        <span
          style={{
            color: '#000',
            ...styling,
          }}
        >
          {type}
        </span>
        <span
          style={{
            position: 'absolute',
            top: '9px',
            transform: 'translateY(-50%)',
            right: '-23px',
          }}
        >
          {showMoreLess.more ? (
            <MoreCarat color={styling.color} />
          ) : (
            <LessCarat color={styling.color} />
          )}
        </span>
      </div>
    );
  };

  const handleMoreLessClick = () => {
    if (showMoreLess.more) {
      setShowMoreLess({ more: false, less: true });
    } else {
      setShowMoreLess({ more: true, less: false });
    }
  };

  const splittedCategories = splitObjectsIntoGroups(currCategories, maxRows);
  if (currCategories.length >= moreLimit) {
    splittedCategories[maxCols - 1]?.push({
      isMoreLess: true,
      type: showMoreLess.more ? 'More' : 'Less',
    });
  }

  const categoriesGrid = [];
  for (let row = 0; row < maxRows; row++) {
    categoriesGrid.push([]);
    for (let col = 0; col < maxCols; col++) {
      const index = row + col * maxRows;
      if (index < totalCategories) {
        categoriesGrid[row].push(currCategories[index]);
      } else {
        categoriesGrid[row].push(null); // Add a placeholder for empty cells
      }
    }
  }

  return splittedCategories.map((col, colIndex) => (
    <div key={colIndex}>
      {col.map((category, idx) => {
        const styling =
          getNavElementStyle?.(idx, parentIndex, props.isParentCategoryHovered) || {};
        return (
          <p
            onMouseEnter={() => onLinkHoverEnter(idx)}
            onMouseLeave={onLinkHoverLeave}
            key={colIndex}
            className={classNames(
              'tw-relative tw-m-0 tw-max-w-fit tw-cursor-pointer tw-select-none tw-text-[12px] tw-font-normal tw-uppercase tw-leading-[15.62px] tw-tracking-[1px] ',
              // idx !== col?.length - 1 ? 'tw-mb-[12px]' : '',
              'tw-mb-[12px]',
              '[&>.underline-comp]:hover:tw-block'
            )}
            onClick={() => handleCategoryClick(category)}
            style={styling}
          >
            {category.isMoreLess && categories.length > moreLimit
              ? displayMoreLess(category.type, styling)
              : category.name}
            {props._RenderUnderlineComponent?.(idx, {
              bottom: -4,
              width: '100%',
              top: undefined,
              height: '1px',
            })}
          </p>
        );
      })}
    </div>
  ));
};

export default RenderCategory;
